import React from 'react';
import {
  Switch,
  Route,
  useHistory,
  useParams,
  Link,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';

import { GetFieldGroup } from '@vidispine/vdt-react';
import { FieldGroupDesignerCustom } from '@vidispine/vdt-materialui';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { fieldgroup as FieldGroupApi } from '@vidispine/vdt-api';

import Divider from '@material-ui/core/Divider';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { SnackbarContext } from './SnackbarContext';

const queryParams = { includeValues: true, data: 'label,displayType,order' };

function MetadataFieldList({ fieldGroupDocument = {} }) {
  const { group: groupList = [] } = fieldGroupDocument;
  const { path } = useRouteMatch();
  return (
    <List>
      {groupList.map(({ name }) => (
        <ListItem key={name} button component={Link} to={`${path}${name}`}>
          <ListItemText primary={name} />
        </ListItem>
      ))}
    </List>
  );
}

function MetadataFieldGroup({ fieldGroupDocument = {} }) {
  const { groupName } = useParams();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { setNotification } = React.useContext(SnackbarContext);
  const onChangeGroup = (newGroupName) => history.push(path.replace(':groupName', newGroupName));
  const { group: groupList = [] } = fieldGroupDocument;
  return (
    <GetFieldGroup groupName={groupName} queryParams={queryParams}>
      <FieldGroupDesignerCustom
        setNotification={setNotification}
        onChangeGroup={onChangeGroup}
        groups={groupList}
        useParams={useParams}
      />
    </GetFieldGroup>
  );
}

export default function MetadataDesigner() {
  const [fieldGroupDocument, setFieldGroupDocument] = React.useState({});
  React.useEffect(() => {
    FieldGroupApi.listFieldGroup().then(({ data }) => setFieldGroupDocument(data));
  }, []);
  const [currentTab, setCurrentTab] = React.useState(
    useLocation().pathname.includes('qibb_metadata_details') ? 1 : 0,
  );
  const metaDataEditorFieldGroups = [
    {
      name: 'Overview',
      path: '/settings/metadata-editor/field-group/qibb_metadata_overview',
    },
    {
      name: 'Details',
      path: '/settings/metadata-editor/field-group/qibb_metadata_details',
    },
  ];
  const history = useHistory();
  const onChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
    history.push(metaDataEditorFieldGroups[newValue].path);
  };
  return (
    <>
      <Tabs variant="scrollable" value={currentTab} onChange={onChangeTab}>
        <Tab label={metaDataEditorFieldGroups[0].name} />
        <Tab label={metaDataEditorFieldGroups[1].name} />
      </Tabs>
      <Divider />
      <div style={{ marginBottom: '30px' }} />
      <Switch>
        <Route exact path="/settings/metadata-editor/field-group/">
          <MetadataFieldList fieldGroupDocument={fieldGroupDocument} />
        </Route>
        <Route exact path="/settings/metadata-editor/field-group/:groupName">
          <MetadataFieldGroup fieldGroupDocument={fieldGroupDocument} />
        </Route>
      </Switch>
    </>
  );
}
