import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import { GetItem, useRoles } from '@vidispine/vdt-react';
import {
  ItemPlayer,
  ItemTabs,
  ItemMetadataTabPrivileged,
  ItemShapeTab,
  ItemAccessTab,
  ItemSourceTab,
  ItemPosterTab,
  ItemParentsTab,
  EntityComment,
} from '@vidispine/vdt-materialui';

import { roles as ROLES } from '@vidispine/vdt-js';
import ItemHeaderPrivileged from './ItemHeaderPrivileged';
import ItemSidecarDialog from './ItemSidecarDialog';
import ItemTranscodeDialog from './ItemTranscodeDialog';
import ItemDeleteDialogPrivileged from './ItemDeleteDialogPrivileged';
import ItemBatonDialog from './ItemBatonDialog';
import ItemAnalyzeDialog from './ItemAnalyzeDialog';
import ItemShrynkDialog from './ItemShrynkDialog';
import ItemHeightscreenDialog from './ItemHeightscreenDialog';
import ExportDialog from '../export/ExportDialog';
import AddToCollectionDialog from '../collection/AddToCollectionDialog';
import { SnackbarContext } from '../SnackbarContext';
import { CATEGORY_OPTIONS } from '../const';

const headerQueryParams = {
  content: ['metadata', 'shape'],
  tag: 'original',
  field: ['title', 'mimeType', 'mediaType', 'created', 'user', 'originalFilename'],
  interval: 'generic',
};

const playerQueryParams = {
  content: ['thumbnail', 'metadata', 'shape'],
  methodMetadata: [{ key: 'format', value: 'SIGNED-AUTO' }],
  'noauth-url': true,
  group: 'stl_subtitle',
  field: 'stl_text',
  sampleRate: 'PAL',
};

const useBatonMenu = () => {
  const timeline = React.useRef('Show everything');
  const getActiveIndex = (e) => e.findIndex(({ title }) => title === timeline.current);
  const [config, setConfig] = React.useState({
    showWarnings: true,
    showErrors: true,
    showInfos: true,
  });
  const onClick = React.useCallback(
    (_, { title }) => {
      timeline.current = title;
      setConfig({
        showInfos: title.includes('info') || title.includes('everything'),
        showErrors: title.includes('error') || title.includes('everything'),
        showWarnings: title.includes('warning') || title.includes('everything'),
      });
    },
    [timeline],
  );

  const menuItems = [
    {
      title: 'Baton',
      getActiveIndex,
      items: [
        { title: 'Off', onClick },
        { title: 'Show info', onClick },
        { title: 'Show errors', onClick },
        { title: 'Show warnings', onClick },
        { title: 'Show everything', onClick },
      ],
    },
  ];

  return { ...config, menuItems };
};

export default function ItemPrivileged({
  itemId: propsItemId,
  startSeconds: propsStartSeconds,
  endSeconds: propsEndSeconds,
  EntityCommentComponent = EntityComment,
  isCinema: initialIsCinema = false,
  ItemHeaderProps = {},
  onVideoEnd,
}) {
  const { t } = useTranslation();
  const { itemId: paramsItemId } = useParams();
  const itemId = propsItemId || paramsItemId;
  const queryParams = new URLSearchParams(useLocation().search);
  const startSeconds = Number(queryParams.get('t') || propsStartSeconds);
  const endSeconds = propsEndSeconds;
  const startSecondsRef = React.useRef(startSeconds);
  const history = useHistory();
  const videoEl = React.createRef();
  React.useEffect(() => {
    if (startSecondsRef.current && videoEl.current) {
      if (startSecondsRef.current !== startSeconds) {
        startSecondsRef.current = startSeconds;
        videoEl.current.player.videoEl.currentTime = startSeconds;
      }
    }
  }, [startSeconds, videoEl]);
  const [isCinema, setIsCinema] = React.useState(initialIsCinema);
  const onCinema = (newIsCinema) => setIsCinema(newIsCinema);
  const { setNotification } = React.useContext(SnackbarContext);
  const listShapeTagRef = React.createRef();
  const listResourceTypeRef = React.createRef();
  const { menuItems } = useBatonMenu();

  const { hasRole } = useRoles();
  const hasAccessControlWriteRight = hasRole(ROLES.ACCESSCONTROL_WRITE);
  const showDeleteAction = hasRole(ROLES.COLLECTION_WRITE);
  const showEditMetadata = hasRole(ROLES.METADATA_WRITE);

  const tabs = [
    {
      label: t('overview'),
      component: ItemMetadataTabPrivileged,
      fields: [
        { name: 'title' },
        { name: 'dsc', label: t('dsc') },
        {
          name: 'tcd',
          label: t('Tags'),
          displayType: 'autocomplete',
          options: [],
          multiple: true,
          freeSolo: true,
        },
        {
          name: 'cct',
          label: t('Category'),
          displayType: 'autocomplete',
          options: CATEGORY_OPTIONS,
        },
      ],
      showEditMetadata,
    },
    {
      label: t('source'),
      component: ItemSourceTab,
    },
    {
      label: t('keyFrames'),
      component: ItemPosterTab,
    },

    {
      label: t('formats'),
      component: ItemShapeTab,
      hideOnMount: true,
    },
    {
      label: t('access'),
      component: ItemAccessTab,
      showAddAccess: hasAccessControlWriteRight || false,
    },
    {
      hideOnMount: false,
      label: t('collections', 'Collections'),
      component: ItemParentsTab,
      CollectionListProps: {
        CollectionListItemProps: {
          primary: 'title',
          secondary: (collectionType = {}) => {
            let {
              __items_size: iSize = '0',
              __child_collection_size: cSize = '0',
            } = collectionType;
            iSize = Number(iSize);
            cSize = Number(cSize);
            return `
            ${iSize > 0 ? `${iSize} ${t(iSize === 1 ? 'item' : 'items')}` : ''}
            ${iSize > 0 && cSize > 0 ? ' ,' : ''}
            ${cSize > 0 ? `${cSize} ${t(cSize === 1 ? 'collection' : 'collections')}` : ''}
          `;
          },
          onClick: (e, { collectionId }) => history.push(`/collection/${collectionId}`),
        },
      },
      queryParams: {
        content: ['metadata'],
        field: ['title', '__items_size', '__child_collection_size'],
      },
      onError: ({ message }) =>
        setNotification({
          open: true,
          message,
          severity: 'error',
        }),
      showDeleteAction,
    },
  ];

  return (
    <>
      <Box>
        <Grid container justify="space-between" spacing={4}>
          <Grid
            item
            style={{
              width: isCinema ? '100%' : '50%',
              marginLeft: isCinema ? 8 : 0,
              marginRight: isCinema ? 8 : 0,
              transition: 'width 0.5s, margin 0.5s',
            }}
          >
            <GetItem itemId={itemId} queryParams={headerQueryParams}>
              <ItemHeaderPrivileged
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...ItemHeaderProps}
              />
            </GetItem>
            <GetItem itemId={itemId} queryParams={playerQueryParams}>
              <ItemPlayer
                itemId={itemId}
                videoEl={videoEl}
                subtitleGroup={playerQueryParams.group}
                subtitleField={playerQueryParams.field}
                onCinema={onCinema}
                ItemVideoPlayerProps={{
                  onScreenshotSuccess: () =>
                    setNotification({ open: true, message: 'Screenshot Job Started' }),
                  onScreenshotError: () =>
                    setNotification({
                      open: true,
                      message: 'Error Starting Screenshot Job',
                      severity: 'error',
                    }),
                }}
                videoPlayerProps={{
                  menuItems,
                  controlsBelowPlayer: true,
                  onReady: (videojs) => {
                    if (!Number.isNaN(startSeconds)) {
                      videojs.currentTime(startSeconds);
                      videojs.poster('');
                    }
                    setTimeout(() => {
                      videojs.play();
                    }, 500);
                    if (onVideoEnd) videojs.on('ended', onVideoEnd);
                    if (onVideoEnd && endSeconds !== undefined && endSeconds !== 0) {
                      videojs.on('timeupdate', () => {
                        if (videojs.currentTime() >= endSeconds) {
                          videojs.pause();
                          onVideoEnd();
                        }
                      });
                    }
                  },
                }}
                audioPlayerProps={{
                  onReady: (videojs) => {
                    setTimeout(() => {
                      videojs.play();
                    }, 500);
                    if (onVideoEnd) videojs.on('ended', onVideoEnd);
                  },
                }}
              />
            </GetItem>
            {EntityCommentComponent && (
              <EntityCommentComponent entityId={itemId} entity="item" playerRef={videoEl} />
            )}
          </Grid>
          <Grid xs={12} lg={isCinema ? 12 : 6} item>
            <ItemTabs
              tabs={tabs}
              itemId={itemId}
              videoEl={videoEl}
              onError={(error) =>
                setNotification({
                  open: true,
                  message: error.message,
                  severity: 'error',
                })
              }
            />
          </Grid>
        </Grid>
      </Box>
      <ItemDeleteDialogPrivileged
        itemId={itemId}
        onSuccess={() => {
          setNotification({ open: true, message: `${itemId} successfully removed` });
          history.push('/housekeeping/');
        }}
        onFailure={({ message = 'There was an error processing your request' }) =>
          setNotification({ open: true, message, severity: 'error' })
        }
        title={`${t('delete')} ${t('item')}`}
        confirmButtonText="delete"
        closeButtonText="cancel"
        validationNeeded={false}
      />
      <ItemSidecarDialog
        itemId={itemId}
        titleText="Captions"
        DialogProps={{ maxWidth: 'sm', fullWidth: true }}
        onSuccessDownload={({ name }) =>
          setNotification({ open: true, message: `${name} downloaded` })
        }
        onFailureDownload={() =>
          setNotification({ open: true, message: 'Error downloading', severity: 'error' })
        }
        onSuccessUpload={({ name }) => setNotification({ open: true, message: `${name} uploaded` })}
        onFailureUpload={() =>
          setNotification({ open: true, message: 'Error uploading', severity: 'error' })
        }
      />
      <ItemTranscodeDialog
        itemId={itemId}
        DialogProps={{ fullWidth: true, maxWidth: 'md' }}
        onSuccess={() => setNotification({ open: true, message: 'Transcode Queued' })}
        onFailure={() =>
          setNotification({
            open: true,
            message: 'Error transcoding',
            severity: 'error',
          })
        }
        listShapeTagRef={listShapeTagRef}
        listResourceTypeRef={listResourceTypeRef}
      />
      <ItemBatonDialog
        itemId={itemId}
        onSuccess={() => setNotification({ open: true, message: 'Baton job started' })}
        onFailure={() =>
          setNotification({
            open: true,
            message: 'Failed to start Baton job',
            severity: 'error',
          })
        }
        DialogProps={{ maxWidth: 'xs', fullWidth: true }}
      />
      <ItemAnalyzeDialog itemId={itemId} DialogProps={{ maxWidth: 'md', fullWidth: true }} />
      <ItemShrynkDialog
        itemId={itemId}
        DialogProps={{ maxWidth: 'md', fullWidth: true }}
        onSuccess={() => setNotification({ open: true, message: 'Job Queued' })}
        onFailure={() =>
          setNotification({
            open: true,
            message: 'Error starting job',
            severity: 'error',
          })
        }
      />
      <ItemHeightscreenDialog itemId={itemId} DialogProps={{ maxWidth: 'md', fullWidth: true }} />
      <ExportDialog itemId={itemId} DialogProps={{ fullWidth: true }} />
      <AddToCollectionDialog
        entities={[{ id: itemId, type: 'item' }]}
        DialogProps={{ fullWidth: true }}
        onSuccess={() =>
          setNotification({ open: true, message: `${itemId} ${t('successfullyAdded')}` })
        }
      />
    </>
  );
}
