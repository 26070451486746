import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import { GetItem, useRoles } from '@vidispine/vdt-react';
import {
  ItemPlayer,
  ItemTabs,
  ItemMetadataTab,
  ItemShapeTab,
  ItemAccessTab,
  ItemSourceTab,
  ItemPosterTab,
  ItemParentsTab,
  EntityComment,
  ItemTimespanTab,
} from '@vidispine/vdt-materialui';

import { roles as ROLES } from '@vidispine/vdt-js';
import { fieldgroup as FieldGroupApi } from '@vidispine/vdt-api';
import useApi from '@vidispine/vdt-react/src/hooks/useApi';
import ItemHeader from './ItemHeader';
import ItemSidecarDialog from './ItemSidecarDialog';
import ItemTranscodeDialog from './ItemTranscodeDialog';
import ItemDeleteDialog from './ItemDeleteDialog';
import ItemBatonDialog from './ItemBatonDialog';
import ItemAnalyzeDialog from './ItemAnalyzeDialog';
import ItemShrynkDialog from './ItemShrynkDialog';
import ItemHeightscreenDialog from './ItemHeightscreenDialog';
import ExportDialog from '../export/ExportDialog';
import AddToCollectionDialog from '../collection/AddToCollectionDialog';
import { SnackbarContext } from '../SnackbarContext';
// import { CATEGORY_OPTIONS } from '../const';

const headerQueryParams = {
  content: ['metadata', 'shape'],
  tag: 'original',
  field: ['title', 'mimeType', 'mediaType', 'created', 'user', 'originalFilename'],
  interval: 'generic',
};

const playerQueryParams = {
  content: ['thumbnail', 'metadata', 'shape'],
  methodMetadata: [{ key: 'format', value: 'SIGNED-AUTO' }],
  'noauth-url': true,
  group: 'stl_subtitle',
  field: 'stl_text',
  sampleRate: 'PAL',
};

/* const vcsTimespanOptions = [
  {
    label: 'Rekognition',
    value: {
      subtitleGroup: 'adu_label_AWSVideoRekognitionAnalyzer',
      subtitleField: 'adu_value',
      aduGroup: 'adu_av_analyzedValue',
      aduValue: 'adu_av_value',
      aduConfidence: 'adu_av_confidence',
    },
  },
  {
    label: 'Transcribe',
    value: {
      subtitleGroup: 'adu_transcript_AWSTranscribeAnalyzer',
      subtitleField: 'adu_value',
    },
  },
  {
    label: 'Moderation',
    value: {
      subtitleGroup: 'adu_moderationLabel_AWSVideoRekognitionAnalyzer',
      subtitleField: 'adu_value',
      aduGroup: 'adu_av_analyzedValue',
      aduValue: 'adu_av_value',
      aduConfidence: 'adu_av_confidence',
    },
  },
  {
    label: 'Celebrity',
    value: {
      subtitleGroup: 'adu_celebrity_AWSVideoRekognitionAnalyzer',
      subtitleField: 'adu_value',
      aduGroup: 'adu_av_analyzedValue',
      aduValue: 'adu_av_value',
      aduConfidence: 'adu_av_confidence',
    },
  },
]; */

function useFieldGroup(groupName) {
  const { request, data: metadataFieldGroupDocument } = useApi(FieldGroupApi.getFieldGroup);
  const onRefresh = () =>
    request({ groupName, queryParams: { includeValues: true, data: 'label,displayType,order' } });
  React.useEffect(() => {
    onRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName]);
  return metadataFieldGroupDocument;
}

const useBatonMenu = () => {
  const timeline = React.useRef('Show everything');
  const getActiveIndex = (e) => e.findIndex(({ title }) => title === timeline.current);
  const [config, setConfig] = React.useState({
    showWarnings: true,
    showErrors: true,
    showInfos: true,
  });
  const onClick = React.useCallback(
    (_, { title }) => {
      timeline.current = title;
      setConfig({
        showInfos: title.includes('info') || title.includes('everything'),
        showErrors: title.includes('error') || title.includes('everything'),
        showWarnings: title.includes('warning') || title.includes('everything'),
      });
    },
    [timeline],
  );

  const menuItems = [
    {
      title: 'Baton',
      getActiveIndex,
      items: [
        { title: 'Off', onClick },
        { title: 'Show info', onClick },
        { title: 'Show errors', onClick },
        { title: 'Show warnings', onClick },
        { title: 'Show everything', onClick },
      ],
    },
  ];

  return { ...config, menuItems };
};

export default function Item({
  itemId: propsItemId,
  startSeconds: propsStartSeconds,
  endSeconds: propsEndSeconds,
  EntityCommentComponent = EntityComment,
  isCinema: initialIsCinema = false,
  ItemHeaderProps = {},
  onVideoEnd,
}) {
  const { t } = useTranslation();
  const { itemId: paramsItemId } = useParams();
  const itemId = propsItemId || paramsItemId;
  const queryParams = new URLSearchParams(useLocation().search);
  const startSeconds = Number(queryParams.get('t') || propsStartSeconds);
  const endSeconds = propsEndSeconds;
  const startSecondsRef = React.useRef(startSeconds);
  const history = useHistory();
  const videoEl = React.createRef();
  React.useEffect(() => {
    if (startSecondsRef.current && videoEl.current) {
      if (startSecondsRef.current !== startSeconds) {
        startSecondsRef.current = startSeconds;
        videoEl.current.player.videoEl.currentTime = startSeconds;
      }
    }
  }, [startSeconds, videoEl]);
  const [isCinema, setIsCinema] = React.useState(initialIsCinema);
  const onCinema = (newIsCinema) => setIsCinema(newIsCinema);
  const { setNotification } = React.useContext(SnackbarContext);
  const listShapeTagRef = React.createRef();
  const listResourceTypeRef = React.createRef();
  const { menuItems } = useBatonMenu();

  const { hasRole } = useRoles();
  const hasAccessControlWriteRight = hasRole(ROLES.ACCESSCONTROL_WRITE);
  const showDeleteAction = hasRole(ROLES.COLLECTION_WRITE);
  const showEditMetadata = hasRole(ROLES.METADATA_WRITE);
  const canWriteJob = hasRole(ROLES.JOB_WRITE);

  const fieldsOverviewDocument = useFieldGroup('qibb_metadata_overview');
  const fieldsDetailsDocument = useFieldGroup('qibb_metadata_details');

  const fieldsOverviewSortOrder = fieldsOverviewDocument?.data
    .find((x) => x.key === 'order')
    ?.value.split(',');
  const fieldsDetailsSortOrder = fieldsDetailsDocument?.data
    .find((x) => x.key === 'order')
    ?.value.split(',');

  const fieldsOverviewMapped = fieldsOverviewDocument?.field?.map((field) => {
    const o = { name: field.name, label: field.data.find((i) => i.key === 'label')?.value };
    if (field.data.find((i) => i.key === 'displayType')?.value === 'tag') {
      o.displayType = 'autocomplete';
      o.options = [];
      o.multiple = true;
      o.freeSolo = true;
    }
    return o;
  });

  const fieldsDetailsMapped = fieldsDetailsDocument?.field.map((field) => {
    const o = { name: field.name, label: field.data.find((i) => i.key === 'label')?.value };
    if (field.data.find((i) => i.key === 'displayType')?.value === 'tag') {
      o.displayType = 'autocomplete';
      o.options = [];
      o.multiple = true;
      o.freeSolo = true;
    }
    if (field.values?.field) {
      o.displayType = 'autocomplete';
      o.options = field.values.field.map((x) => x.value);
    }
    return o;
  });

  const fieldsOverviewPreSorted = fieldsOverviewSortOrder?.map((fieldName) => {
    return fieldsOverviewMapped?.find((i) => i.name === fieldName);
  });

  const fieldsDetailsPreSorted = fieldsDetailsSortOrder?.map((fieldName) => {
    return fieldsDetailsMapped?.find((i) => i.name === fieldName);
  });

  const fieldsOverview = fieldsOverviewPreSorted?.filter((i) => i !== undefined);
  const fieldsDetails = fieldsDetailsPreSorted?.filter((i) => i !== undefined);

  const tabs = [
    {
      label: t('overview'),
      component: ItemMetadataTab,
      fields: fieldsOverview || [{ name: '' }],
      showEditMetadata,
    },
    {
      label: t('details'),
      component: ItemMetadataTab,
      fields: fieldsDetails || [{ name: '' }],
      showEditMetadata,
    },
    {
      label: t('source'),
      component: ItemSourceTab,
    },
    {
      label: t('transcript'),
      component: ItemTimespanTab,
      timespanOptions: [
        {
          label: t('subtitle'),
          value: {
            subtitleGroup: 'qibb_transcript_data',
            subtitleField: 'qibb_transcript',
            aduValue: 'qibb_transcript_confidence',
          },
        },
      ],
      canEdit: false,
      isFilterEnabled: true,
      isDurationEnabled: false,
    },
    {
      label: t('keyFrames'),
      component: ItemPosterTab,
    },
    {
      label: t('formats'),
      component: ItemShapeTab,
      hideOnMount: true,
    },
    {
      label: t('access'),
      component: ItemAccessTab,
      showAddAccess: hasAccessControlWriteRight || false,
    },
    {
      hideOnMount: false,
      label: t('collections', 'Collections'),
      component: ItemParentsTab,
      CollectionListProps: {
        CollectionListItemProps: {
          primary: 'title',
          secondary: (collectionType = {}) => {
            let {
              __items_size: iSize = '0',
              __child_collection_size: cSize = '0',
            } = collectionType;
            iSize = Number(iSize);
            cSize = Number(cSize);
            return `
            ${iSize > 0 ? `${iSize} ${t(iSize === 1 ? 'item' : 'items')}` : ''}
            ${iSize > 0 && cSize > 0 ? ' ,' : ''}
            ${cSize > 0 ? `${cSize} ${t(cSize === 1 ? 'collection' : 'collections')}` : ''}
          `;
          },
          onClick: (e, { collectionId }) => history.push(`/collection/${collectionId}`),
        },
      },
      queryParams: {
        content: ['metadata'],
        field: ['title', '__items_size', '__child_collection_size'],
      },
      onError: ({ message }) =>
        setNotification({
          open: true,
          message,
          severity: 'error',
        }),
      showDeleteAction,
    },
  ];

  return (
    <>
      <Box>
        <Grid container justify="space-between" spacing={4}>
          <Grid
            item
            style={{
              width: isCinema ? '100%' : '50%',
              marginLeft: isCinema ? 8 : 0,
              marginRight: isCinema ? 8 : 0,
              transition: 'width 0.5s, margin 0.5s',
            }}
          >
            <GetItem itemId={itemId} queryParams={headerQueryParams}>
              <ItemHeader
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...ItemHeaderProps}
              />
            </GetItem>
            <GetItem itemId={itemId} queryParams={playerQueryParams}>
              <ItemPlayer
                itemId={itemId}
                videoEl={videoEl}
                subtitleGroup={playerQueryParams.group}
                subtitleField={playerQueryParams.field}
                onCinema={onCinema}
                onScreenshot={canWriteJob}
                ItemVideoPlayerProps={{
                  onScreenshotSuccess: () =>
                    setNotification({ open: true, message: 'Snapshot Job Started' }),
                  onScreenshotError: () =>
                    setNotification({
                      open: true,
                      message: 'Error Starting Snapshot Job',
                      severity: 'error',
                    }),
                }}
                videoPlayerProps={{
                  menuItems,
                  controlsBelowPlayer: true,
                  onReady: (videojs) => {
                    if (!Number.isNaN(startSeconds)) {
                      videojs.currentTime(startSeconds);
                      videojs.poster('');
                    }
                    setTimeout(() => {
                      videojs.play();
                    }, 500);
                    if (onVideoEnd) videojs.on('ended', onVideoEnd);
                    if (onVideoEnd && endSeconds !== undefined && endSeconds !== 0) {
                      videojs.on('timeupdate', () => {
                        if (videojs.currentTime() >= endSeconds) {
                          videojs.pause();
                          onVideoEnd();
                        }
                      });
                    }
                  },
                }}
                audioPlayerProps={{
                  onReady: (videojs) => {
                    setTimeout(() => {
                      videojs.play();
                    }, 500);
                    if (onVideoEnd) videojs.on('ended', onVideoEnd);
                  },
                }}
              />
            </GetItem>
            {EntityCommentComponent && (
              <EntityCommentComponent
                entityId={itemId}
                entity="item"
                playerRef={videoEl}
                isDisabled={!showEditMetadata}
              />
            )}
          </Grid>
          <Grid xs={12} lg={isCinema ? 12 : 6} item style={{ marginTop: '10px' }}>
            <ItemTabs
              tabs={tabs}
              itemId={itemId}
              videoEl={videoEl}
              onError={(error) =>
                setNotification({
                  open: true,
                  message: error.message,
                  severity: 'error',
                })
              }
            />
          </Grid>
        </Grid>
      </Box>
      <ItemDeleteDialog
        itemId={itemId}
        onSuccess={() => {
          setNotification({ open: true, message: `${itemId} successfully removed` });
          history.push('/archive/');
        }}
        onFailure={({ message = 'There was an error processing your request' }) =>
          setNotification({ open: true, message, severity: 'error' })
        }
        title={`${t('delete')} ${t('item')}`}
        confirmButtonText="delete"
        closeButtonText="cancel"
        validationNeeded={false}
      />
      <ItemSidecarDialog
        itemId={itemId}
        titleText="Captions"
        DialogProps={{ maxWidth: 'sm', fullWidth: true }}
        onSuccessDownload={({ name }) =>
          setNotification({ open: true, message: `${name} downloaded` })
        }
        onFailureDownload={() =>
          setNotification({ open: true, message: 'Error downloading', severity: 'error' })
        }
        onSuccessUpload={({ name }) => setNotification({ open: true, message: `${name} uploaded` })}
        onFailureUpload={() =>
          setNotification({ open: true, message: 'Error uploading', severity: 'error' })
        }
      />
      <ItemTranscodeDialog
        itemId={itemId}
        DialogProps={{ fullWidth: false, maxWidth: 'sm' }}
        onSuccess={() => setNotification({ open: true, message: 'Transcode Queued' })}
        onFailure={() =>
          setNotification({
            open: true,
            message: 'Error transcoding',
            severity: 'error',
          })
        }
        listShapeTagRef={listShapeTagRef}
        listResourceTypeRef={listResourceTypeRef}
      />
      <ItemBatonDialog
        itemId={itemId}
        onSuccess={() => setNotification({ open: true, message: 'Baton job started' })}
        onFailure={() =>
          setNotification({
            open: true,
            message: 'Failed to start Baton job',
            severity: 'error',
          })
        }
        DialogProps={{ maxWidth: 'xs', fullWidth: true }}
      />
      <ItemAnalyzeDialog itemId={itemId} DialogProps={{ maxWidth: 'md', fullWidth: true }} />
      <ItemShrynkDialog
        itemId={itemId}
        DialogProps={{ maxWidth: 'md', fullWidth: true }}
        onSuccess={() => setNotification({ open: true, message: 'Job Queued' })}
        onFailure={() =>
          setNotification({
            open: true,
            message: 'Error starting job',
            severity: 'error',
          })
        }
      />
      <ItemHeightscreenDialog itemId={itemId} DialogProps={{ maxWidth: 'md', fullWidth: true }} />
      <ExportDialog itemId={itemId} DialogProps={{ fullWidth: true }} />
      <AddToCollectionDialog
        entities={[{ id: itemId, type: 'item' }]}
        DialogProps={{ fullWidth: true }}
        onSuccess={() =>
          setNotification({ open: true, message: `${itemId} ${t('successfullyAdded')}` })
        }
      />
    </>
  );
}
